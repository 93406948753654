var W = {
    0: 8203,
    1: 8204,
    2: 8205,
    3: 8290,
    4: 8291,
    5: 8288,
    6: 65279,
    7: 8289,
    8: 119155,
    9: 119156,
    a: 119157,
    b: 119158,
    c: 119159,
    d: 119160,
    e: 119161,
    f: 119162
  },
  L = {
    0: 8203,
    1: 8204,
    2: 8205,
    3: 65279
  },
  j = new Array(4).fill(String.fromCodePoint(L[0])).join(""),
  V = "\0";
function U(t) {
  let n = JSON.stringify(t);
  return `${j}${Array.from(n).map(o => {
    let e = o.charCodeAt(0);
    if (e > 255) throw new Error(`Only ASCII edit info can be encoded. Error attempting to encode ${n} on character ${o} (${e})`);
    return Array.from(e.toString(4).padStart(4, "0")).map(r => String.fromCodePoint(L[r])).join("");
  }).join("")}`;
}
function Z(t) {
  return !Number.isNaN(Number(t)) || /[a-z]/i.test(t) && !/\d+(?:[-:\/]\d+){2}(?:T\d+(?:[-:\/]\d+){1,2}(\.\d+)?Z?)?/.test(t) ? !1 : !!Date.parse(t);
}
function q(t) {
  try {
    new URL(t, t.startsWith("/") ? "https://acme.com" : void 0);
  } catch (n) {
    return !1;
  }
  return !0;
}
function G(t, n, o = "auto") {
  return o === !0 || o === "auto" && (Z(t) || q(t)) ? t : `${t}${U(n)}`;
}
var H = Object.fromEntries(Object.entries(L).map(t => t.reverse())),
  k = Object.fromEntries(Object.entries(W).map(t => t.reverse())),
  K = `${Object.values(W).map(t => `\\u{${t.toString(16)}}`).join("")}`,
  O = new RegExp(`[${K}]{4,}`, "gu");
function X(t) {
  let n = t.match(O);
  if (n) return Y(n[0], !0)[0];
}
function Y(t, n = !1) {
  let o = Array.from(t);
  if (o.length % 2 === 0) {
    if (o.length % 4 || !t.startsWith(j)) return ee(o, n);
  } else throw new Error("Encoded data has invalid length");
  let e = [];
  for (let r = o.length * 0.25; r--;) {
    let s = o.slice(r * 4, r * 4 + 4).map(c => H[c.codePointAt(0)]).join("");
    e.unshift(String.fromCharCode(parseInt(s, 4)));
  }
  if (n) {
    e.shift();
    let r = e.indexOf(V);
    return r === -1 && (r = e.length), [JSON.parse(e.slice(0, r).join(""))];
  }
  return e.join("").split(V).filter(Boolean).map(r => JSON.parse(r));
}
function ee(t, n) {
  var o;
  let e = [];
  for (let i = t.length * 0.5; i--;) {
    let u = `${k[t[i * 2].codePointAt(0)]}${k[t[i * 2 + 1].codePointAt(0)]}`;
    e.unshift(String.fromCharCode(parseInt(u, 16)));
  }
  let r = [],
    s = [e.join("")],
    c = 10;
  for (; s.length;) {
    let i = s.shift();
    try {
      if (r.push(JSON.parse(i)), n) return r;
    } catch (u) {
      if (!c--) throw u;
      let a = +((o = u.message.match(/\sposition\s(\d+)$/)) == null ? void 0 : o[1]);
      if (!a) throw u;
      s.unshift(i.substring(0, a), i.substring(a));
    }
  }
  return r;
}
function te(t) {
  var n;
  return {
    cleaned: t.replace(O, ""),
    encoded: ((n = t.match(O)) == null ? void 0 : n[0]) || ""
  };
}
function w(t, n) {
  return G(t, n);
}
function ce(t) {
  return U(t);
}
function ie(t) {
  return X(t);
}
function ae(t) {
  return te(t);
}
var x, F;
function ne() {
  if (F) return x;
  F = 1;
  var t = Object.prototype.hasOwnProperty,
    n = Object.prototype.toString;
  return x = function (e, r, s) {
    if (n.call(r) !== "[object Function]") throw new TypeError("iterator must be a function");
    var c = e.length;
    if (c === +c) for (var i = 0; i < c; i++) r.call(s, e[i], i, e);else for (var u in e) t.call(e, u) && r.call(s, e[u], u, e);
  }, x;
}
var I, _;
function re() {
  if (_) return I;
  _ = 1;
  var t = ne();
  I = n;
  function n(o, e, r) {
    if (arguments.length === 3) return n.set(o, e, r);
    if (arguments.length === 2) return n.get(o, e);
    var s = n.bind(n, o);
    for (var c in n) n.hasOwnProperty(c) && (s[c] = n[c].bind(s, o));
    return s;
  }
  return n.get = function (e, r) {
    for (var s = Array.isArray(r) ? r : n.parse(r), c = 0; c < s.length; ++c) {
      var i = s[c];
      if (!(typeof e == "object" && i in e)) throw new Error("Invalid reference token: " + i);
      e = e[i];
    }
    return e;
  }, n.set = function (e, r, s) {
    var c = Array.isArray(r) ? r : n.parse(r),
      i = c[0];
    if (c.length === 0) throw Error("Can not set the root object");
    for (var u = 0; u < c.length - 1; ++u) {
      var a = c[u];
      typeof a != "string" && typeof a != "number" && (a = String(a)), !(a === "__proto__" || a === "constructor" || a === "prototype") && (a === "-" && Array.isArray(e) && (a = e.length), i = c[u + 1], a in e || (i.match(/^(\d+|-)$/) ? e[a] = [] : e[a] = {}), e = e[a]);
    }
    return i === "-" && Array.isArray(e) && (i = e.length), e[i] = s, this;
  }, n.remove = function (o, e) {
    var r = Array.isArray(e) ? e : n.parse(e),
      s = r[r.length - 1];
    if (s === void 0) throw new Error('Invalid JSON pointer for remove: "' + e + '"');
    var c = n.get(o, r.slice(0, -1));
    if (Array.isArray(c)) {
      var i = +s;
      if (s === "" && isNaN(i)) throw new Error('Invalid array index: "' + s + '"');
      Array.prototype.splice.call(c, i, 1);
    } else delete c[s];
  }, n.dict = function (e, r) {
    var s = {};
    return n.walk(e, function (c, i) {
      s[i] = c;
    }, r), s;
  }, n.walk = function (e, r, s) {
    var c = [];
    s = s || function (i) {
      var u = Object.prototype.toString.call(i);
      return u === "[object Object]" || u === "[object Array]";
    }, function i(u) {
      t(u, function (a, l) {
        c.push(String(l)), s(a) ? i(a) : r(a, n.compile(c)), c.pop();
      });
    }(e);
  }, n.has = function (e, r) {
    try {
      n.get(e, r);
    } catch (s) {
      return !1;
    }
    return !0;
  }, n.escape = function (e) {
    return e.toString().replace(/~/g, "~0").replace(/\//g, "~1");
  }, n.unescape = function (e) {
    return e.replace(/~1/g, "/").replace(/~0/g, "~");
  }, n.parse = function (e) {
    if (e === "") return [];
    if (e.charAt(0) !== "/") throw new Error("Invalid JSON pointer: " + e);
    return e.substring(1).split(/\//).map(n.unescape);
  }, n.compile = function (e) {
    return e.length === 0 ? "" : "/" + e.map(n.escape).join("/");
  }, I;
}
var p = re();
const oe = ({
    pointer: t,
    mappings: n,
    data: o,
    hiddenStrings: e
  }) => {
    const r = n[t];
    if (r) {
      delete n[t];
      const s = C(o, t);
      for (const c of s) {
        n[c] = r;
        const i = p.get(o, c),
          u = w(i, e);
        p.set(o, c, u);
      }
    } else {
      const s = C(o, t);
      for (const c of s) {
        const i = p.get(o, c),
          u = w(i, e);
        p.set(o, c, u);
      }
    }
  },
  C = (t, n = "") => {
    const o = [],
      e = p.get(t, n);
    if (e.content) for (let r = 0; r < e.content.length; r++) e.content[r].nodeType === "text" ? o.push(`${n}/content/${r}/value`) : o.push(...C(t, `${n}/content/${r}`));
    return o;
  },
  b = ({
    entityId: t,
    entityType: n,
    space: o,
    environment: e,
    field: r,
    locale: s,
    editorInterface: c,
    fieldType: i,
    targetOrigin: u,
    platform: a
  }) => {
    const d = {
      origin: "contentful.com",
      href: `${`${u || "https://app.contentful.com"}/spaces/${o}/environments/${e}`}/${n === "Entry" ? "entries" : "assets"}/${t}/?focusedField=${r}&focusedLocale=${s}&source=vercel-content-link`,
      contentful: {
        editorInterface: c,
        fieldType: i
      }
    };
    return a === "vercel" && delete d.contentful, d;
  },
  B = t => ["builtin", "sidebar-builtin", "editor-builtin"].includes(t),
  D = t => se.includes(t);
function Q(t) {
  if (typeof structuredClone == "function") return structuredClone(t);
  try {
    return JSON.parse(JSON.stringify(t));
  } catch (n) {
    return console.warn("Failed to clone data:", t, n), t;
  }
}
const se = ["singleLine", "tagEditor", "listInput", "checkbox", "richTextEditor", "multipleLine"];
function P(t, n, o, e, r, s, c) {
  const i = c ? n[c] : n;
  switch (t) {
    case "Symbol":
      {
        const u = w(i, o);
        p.set(e, r, u);
        break;
      }
    case "Text":
      {
        const u = w(i, o);
        p.set(e, r, u);
        break;
      }
    case "RichText":
      {
        oe({
          pointer: "",
          mappings: s,
          data: i,
          hiddenStrings: o
        });
        break;
      }
    case "Array":
      {
        const u = i.map(a => typeof a == "string" ? w(a, o) : a);
        p.set(e, r, u);
        break;
      }
  }
}
const ue = (t, n, o) => {
    if (!t || !t.extensions || !t.extensions.contentSourceMaps) return console.error("GraphQL response does not contain Content Source Maps information.", t), t;
    const e = Q(t),
      {
        spaces: r,
        environments: s,
        editorInterfaces: c,
        fields: i,
        locales: u,
        entries: a,
        assets: l,
        mappings: m,
        fieldTypes: v
      } = e.extensions.contentSourceMaps,
      y = e;
    for (const d in m) {
      const {
          source: f
        } = m[d],
        h = "entry" in f ? a[f.entry] : l[f.asset],
        M = "entry" in f ? "Entry" : "Asset";
      if (!h) return e;
      const g = r[h.space],
        A = s[h.environment],
        E = h.id,
        S = i[f.field],
        T = u[f.locale],
        N = c[f.editorInterface],
        J = v[f.fieldType];
      if (!(B(N.widgetNamespace) && !D(N.widgetId)) && p.has(y, d)) {
        const R = p.get(y, d);
        if (R !== null) {
          const z = b({
            entityId: E,
            entityType: M,
            space: g,
            environment: A,
            field: S,
            locale: T,
            editorInterface: N,
            fieldType: J,
            targetOrigin: n,
            platform: o
          });
          P(J, R, z, y, d, m);
        }
      }
    }
    return e;
  },
  $ = (t, n, o, e, r) => {
    if (!t.fields) return;
    const {
      contentSourceMaps: s
    } = t.sys;
    if (!s) {
      console.error("Content source maps data is missing");
      return;
    }
    const {
      mappings: c
    } = s;
    for (const i in c) {
      const {
          source: u
        } = c[i],
        a = t.sys.space.sys.id,
        l = t.sys.environment.sys.id,
        m = t.sys.id,
        v = t.sys.type,
        y = n[u.fieldType],
        d = o[u.editorInterface];
      if (B(d.widgetNamespace) && !D(d.widgetId)) continue;
      const f = i.startsWith("/") ? i : `/${i}`;
      if (p.has(t, f)) {
        const h = p.get(t, f);
        if (h === null) return;
        const g = f.split("/").pop();
        if (!g) {
          console.error("Field name could not be extracted from the pointer", f);
          return;
        }
        const A = t.sys.locale;
        if (A) {
          const E = b({
            entityId: m,
            entityType: v,
            space: a,
            environment: l,
            field: g,
            locale: A,
            editorInterface: d,
            fieldType: y,
            targetOrigin: e,
            platform: r
          });
          P(y, h, E, t, f, c);
        } else Object.keys(h).forEach(S => {
          const T = b({
            entityId: m,
            entityType: v,
            space: a,
            environment: l,
            field: g,
            locale: S,
            editorInterface: d,
            fieldType: y,
            targetOrigin: e,
            platform: r
          });
          P(y, h, T, t, `${f}/${S}`, c, S);
        });
      }
    }
  },
  fe = (t, n, o) => {
    var r;
    const e = Q(t);
    if (e.sys && "items" in e) {
      const s = e;
      if (!((r = s.sys) != null && r.contentSourceMapsLookup)) return console.error("Content source maps lookup data is missing"), s;
      const {
          contentSourceMapsLookup: {
            fieldTypes: c,
            editorInterfaces: i
          }
        } = s.sys,
        {
          items: u,
          includes: a
        } = s;
      u.forEach(l => $(l, c, i, n, o)), a && a.Entry && a.Entry.forEach(l => $(l, c, i, n, o)), a && a.Asset && a.Asset.forEach(l => $(l, c, i, n, o));
    } else {
      const s = e;
      if (!s.sys.contentSourceMapsLookup) return console.error("Content source maps lookup data is missing"), s;
      $(s, s.sys.contentSourceMapsLookup.fieldTypes, s.sys.contentSourceMapsLookup.editorInterfaces, n, o);
    }
    return e;
  };
export { se as SUPPORTED_WIDGETS, Q as clone, w as combine, b as createSourceMapMetadata, ie as decode, ce as encode, fe as encodeCPAResponse, P as encodeField, ue as encodeGraphQLResponse, oe as encodeRichTextValue, B as isBuiltinNamespace, D as isSupportedWidget, ae as splitEncoding };
